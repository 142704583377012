import React from "react";
import { Modal } from "antd";
import "./index.less";
import Http from "../../../../utils/Http";
import { message } from "antd";
import moment from "moment";
import axios from "axios";

// new 玩法介绍弹窗
const PlayRulesModal = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  return (
    <Modal
      title={null}
      visible={visible}
      closable={false}
      footer={null}
      onCancel={close}
      className="ap_modal_party_rules"
    >
      <div className="ap_modal2">
        <div className="ap_modal_box2">
          <div
            className="ap_modal_header2"
            style={{
              backgroundImage: `url(${require("../../../../assets/bg_popUp_top@2x.png")})`,
            }}
          >
            <span className="title">玩法介绍</span>
            <div
              className="course"
              onClick={() => {
                props.course.current.show();
                close();
              }}
            >
              <span>查看教程</span>
            </div>
          </div>
          <div className="ap_modal_body2">
            <div className="play_rules">
              <img
                src={require("../../../../assets/images/game/img_play_introduction@2x.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default PlayRulesModal;
