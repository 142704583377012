import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { setToken } from "../../../../store/app";
import { setUserId } from "../../../../store/user";
import axios from "axios";
import { baseUrl1, baseUrl2 } from "../../../../config";
import "./index.css";

// new 游戏蒙版
const GameMainModel = (props) => {
  const { open, setOpen } = props;
  const paramsStr = window.location.search;
  const params = new URLSearchParams(paramsStr);
  const token = params.get("token");
  const app_version = params.get("app_version");
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState(1);

  // 获取积分
  const getUserWalletList = async () => {
    setOpen(true);
    try {
      const { data } = await axios({
        method: "post", //请求方法
        headers: {
          token: token,
        },
        data: {
          token: token,
        },
        url: `${baseUrl2}/api/v1/user/walletList`,
      });

      if (data?.status === 10000) {
        const wallet = data?.data?.find((item) => item?.asset === "jifen");
        dispatch(setUserId(wallet?.user_id ?? 0));
        setStatus(0);
        setTimeout(async () => {
          if (app_version && app_version !== "") {
            if ((await compareVersion(app_version, "1.0.0")) === -1) {
              setStatus(3);
              setOpen(true);
            } else {
              setOpen(false);
            }
          } else {
            setStatus(3);
            setOpen(true);
          }
        }, 2000);
      } else if (data?.status === 10202) {
        // message.error("游戏加载失败");
        setStatus(1);
      } else {
        // message.error(data?.message);
        setStatus(1);
      }
    } catch (error) {
      // message.error("网络错误，请检查网络设置");
      setStatus(1);
    } finally {
    }
  };
  // 版本比较 -1低版本 0版本相等 1新版本
  const compareVersion = (version1, version2) => {
    const v1 = version1.split(".");
    const v2 = version2.split(".");
    for (let i = 0; i < v1.length || i < v2.length; i++) {
      let x = 0,
        y = 0;
      if (i < v1.length) {
        x = parseInt(v1[i]);
      }
      if (i < v2.length) {
        y = parseInt(v2[i]);
      }
      if (x > y) return 1;
      if (x < y) return -1;
    }
    return 0;
  };
  const handleFun = async () => {
    if (token && token !== "") {
      await dispatch(setToken(token));
      await getUserWalletList();
    }
    if (app_version && app_version !== "") {
      if ((await compareVersion(app_version, "1.0.0")) === -1) {
        setStatus(3);
        setOpen(true);
      }
    } else {
      setStatus(3);
      setOpen(true);
    }
  };
  useEffect(() => {
    handleFun();
  }, [token, app_version]);

  return (
    <>
      {open && (
        <div className="game_main_model">
          <div className="game_main_model_box">
            <div
              className="game_main_model_content"
              style={{
                backgroundImage: `url(${require("../../../../assets/bg.png")})`,
              }}
            >
              {status === 0 && <span className="m_tags">游戏加载中...</span>}
              {status === 1 && <span className="m_tags">游戏加载失败...</span>}
              {status === 2 && <span className="m_tags">系统正在维护...</span>}
              {status === 3 && (
                <span className="m_tags">您的APP版本过低，请更新...</span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GameMainModel;
