import React from "react";
import "./index.less";

const bo = require("../../../../assets/bo.gif");

const MonsterBlast = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);

  const show = () => {
    setVisible(true);
    setTimeout(() => {
      close();
    }, 1000);
  };
  const close = () => {
    setVisible(false);
  };

  React.useImperativeHandle(ref, () => {
    return {
      show: show,
    };
  });
  return (
    <>
      {visible && (
        <div className="game_monster_blast" style={props.style}>
          <img
            src={bo}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt=""
          />
        </div>
      )}
    </>
  );
});

export default MonsterBlast;
