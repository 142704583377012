import React from "react";
import "./index.css";

let daojishi_timer;
// new 倒计时弹窗
const DaoJiShiModel = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [shiJian, setShiJian] = React.useState(3);
  const close = () => {
    setVisible(false);
  };
  const show = (turret) => {
    setVisible(true);
    let t = 3;
    clearInterval(daojishi_timer);
    daojishi_timer = setInterval(() => {
      t--;
      setShiJian(t);
      if (t <= 0) {
        // close();
        setShiJian(3);
        clearInterval(daojishi_timer);
      }
    }, 1000);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  return (
    <>
      {visible && (
        <div className="daojishi_model">
          <div
            className="daojishi_box"
            style={{
              backgroundImage: `url(${require("../../../../assets/daojishi1.png")})`,
            }}
          >
            <span className="daojishi_content">{shiJian}</span>
          </div>
        </div>
      )}
    </>
  );
});

export default DaoJiShiModel;
