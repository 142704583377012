import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") ?? undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      if (action.payload) {
        state.userId = action.payload;
        localStorage.setItem("userId", action.payload);
      } else {
        localStorage.removeItem("userId");
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserId } = userSlice.actions;

export default userSlice.reducer;
