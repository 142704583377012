import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import "./index.less";
import { baseUrl1, baseUrl2 } from "../../../../config";
import axios from "axios";
import { message } from "antd";
import moment from "moment";

// new 消费记录弹窗
const ConsumeModal = React.forwardRef((props, ref) => {
  const userId = useSelector((state) => state.User.userId);
  const [visible, setVisible] = React.useState(false);
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const loadMoreData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data: res } = await axios({
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          user_id: userId,
        },
        url: `${baseUrl1}/api/v1/dinosaur_paradise/userConsumeRecord`,
      });
      if (res.status === 10000) {
        setData(res?.data);
        // setData([
        //   {
        //     id: 3329379,
        //     created_at: "2024-07-08T17:15:18+08:00",
        //     updated_at: "2024-07-08T17:15:32+08:00",
        //     user_id: 200008,
        //     uid: "",
        //     avatar: "",
        //     paradise_id: 670771,
        //     dinosaur_id: 1,
        //     dinosaur_info: [
        //       {
        //         id: 1,
        //         created_at: "2022-05-26T21:43:37+08:00",
        //         updated_at: "2024-07-08T17:25:31+08:00",
        //         name: "大话猫",
        //         icon: "https://g1.ningmengxinxi.cn/file/b1a49865-8d38-4313-973d-95967541f029.gif",
        //         fear_turret_id: 1,
        //         fear_turret_info: {
        //           id: 1,
        //           created_at: "2022-05-25T23:33:32+08:00",
        //           updated_at: "2023-03-02T10:51:58+08:00",
        //           name: "肥鱼",
        //           icon: "https://g1.ningmengxinxi.cn/file/afb28941-62d9-4fcf-824c-3d350e6d10ca.png",
        //           pay_asset: "",
        //           pay_amount: "10",
        //           sequence: 0,
        //           dinosaur_info: {
        //             id: 0,
        //             created_at: "0001-01-01T00:00:00Z",
        //             updated_at: "0001-01-01T00:00:00Z",
        //             name: "",
        //             icon: "",
        //             fear_turret_id: 0,
        //             category: 0,
        //             hit_points: 0,
        //             gif_path: "",
        //           },
        //           gif_path:
        //             "https://g1.ningmengxinxi.cn/file/afb28941-62d9-4fcf-824c-3d350e6d10ca.png",
        //         },
        //         award_goods_id: 664,
        //         goods_info: {
        //           id: 0,
        //           created_at: null,
        //           updated_at: null,
        //           deleted_at: null,
        //           name: "",
        //           desc: "",
        //           icon_path: "",
        //           show_resource_path: "",
        //           tid: "",
        //           sequence: 0,
        //           price: "",
        //         },
        //         goods_name: "",
        //         goods_pic: "",
        //         category: 0,
        //         hit_points: 1,
        //         status: 1,
        //         gif_path:
        //           "https://g1.ningmengxinxi.cn/file/b1a49865-8d38-4313-973d-95967541f029.gif",
        //         probability: "24.6",
        //         game_notice: 0,
        //       },
        //       {
        //         id: 8,
        //         created_at: "2022-05-26T21:55:00+08:00",
        //         updated_at: "2024-07-08T17:27:32+08:00",
        //         name: "小肥羊",
        //         icon: "https://g1.ningmengxinxi.cn/file/04a955a6-a1d3-4591-9899-e5004a9e8de3.gif",
        //         fear_turret_id: 4,
        //         fear_turret_info: {
        //           id: 4,
        //           created_at: "2022-05-26T02:00:33+08:00",
        //           updated_at: "2023-03-07T14:37:44+08:00",
        //           name: "玉米",
        //           icon: "https://g1.ningmengxinxi.cn/file/fc45245d-603a-4acf-9ae6-76c924160162.png",
        //           pay_asset: "",
        //           pay_amount: "10",
        //           sequence: 3,
        //           dinosaur_info: {
        //             id: 0,
        //             created_at: "0001-01-01T00:00:00Z",
        //             updated_at: "0001-01-01T00:00:00Z",
        //             name: "",
        //             icon: "",
        //             fear_turret_id: 0,
        //             category: 0,
        //             hit_points: 0,
        //             gif_path: "",
        //           },
        //           gif_path:
        //             "https://g1.ningmengxinxi.cn/file/fc45245d-603a-4acf-9ae6-76c924160162.png",
        //         },
        //         award_goods_id: 667,
        //         goods_info: {
        //           id: 0,
        //           created_at: null,
        //           updated_at: null,
        //           deleted_at: null,
        //           name: "",
        //           desc: "",
        //           icon_path: "",
        //           show_resource_path: "",
        //           tid: "",
        //           sequence: 0,
        //           price: "",
        //         },
        //         goods_name: "",
        //         goods_pic: "",
        //         category: 0,
        //         hit_points: 1,
        //         status: 1,
        //         gif_path:
        //           "https://g1.ningmengxinxi.cn/file/04a955a6-a1d3-4591-9899-e5004a9e8de3.gif",
        //         probability: "7.7",
        //         game_notice: 0,
        //       },
        //     ],
        //     pay_asset: "",
        //     pay_amount: "30",
        //     status: 1,
        //     goods_id: 664,
        //     goods_info: {
        //       id: 664,
        //       created_at: "2024-04-30T15:10:48+08:00",
        //       updated_at: "2024-07-08T17:25:31+08:00",
        //       deleted_at: null,
        //       name: "热力吉他",
        //       desc: "热力吉他",
        //       icon_path:
        //         "https://ningmeng-info.oss-cn-guangzhou.aliyuncs.com/file/e1f49215-fd41-41ef-adb2-2ff4ac658f3d.png",
        //       show_resource_path:
        //         "https://ningmeng-info.oss-cn-guangzhou.aliyuncs.com/file/92ab6800-7632-4c67-bba6-75e89ffd5d4b.svga.svga",
        //       tid: "RELIJITA",
        //       sequence: 0,
        //       price: "40",
        //     },
        //     tid: "RELIJITA",
        //     goods_amount: 1,
        //     tx_no: "UPTX1030235271460818944",
        //     year: 2024,
        //     month: 7,
        //     day: 8,
        //     count: 3,
        //     turret_list: [
        //       {
        //         id: 9082229,
        //         created_at: "2024-07-08T17:15:18+08:00",
        //         updated_at: "2024-07-08T17:15:18+08:00",
        //         paradise_id: 670771,
        //         user_id: 200008,
        //         uid: "",
        //         avatar: "",
        //         turret_id: 1,
        //         turret_info: {
        //           id: 1,
        //           created_at: "2022-05-25T23:33:32+08:00",
        //           updated_at: "2023-03-02T10:51:58+08:00",
        //           name: "肥鱼",
        //           icon: "https://g1.ningmengxinxi.cn/file/afb28941-62d9-4fcf-824c-3d350e6d10ca.png",
        //           pay_asset: "",
        //           pay_amount: "10",
        //           sequence: 0,
        //           dinosaur_info: {
        //             id: 0,
        //             created_at: "0001-01-01T00:00:00Z",
        //             updated_at: "0001-01-01T00:00:00Z",
        //             name: "",
        //             icon: "",
        //             fear_turret_id: 0,
        //             category: 0,
        //             hit_points: 0,
        //             gif_path: "",
        //           },
        //           gif_path:
        //             "https://g1.ningmengxinxi.cn/file/afb28941-62d9-4fcf-824c-3d350e6d10ca.png",
        //         },
        //         level: 1,
        //         sequence: 0,
        //       },
        //       {
        //         id: 9082231,
        //         created_at: "2024-07-08T17:15:19+08:00",
        //         updated_at: "2024-07-08T17:15:19+08:00",
        //         paradise_id: 670771,
        //         user_id: 200008,
        //         uid: "",
        //         avatar: "",
        //         turret_id: 2,
        //         turret_info: {
        //           id: 2,
        //           created_at: "2022-05-26T01:59:26+08:00",
        //           updated_at: "2023-03-02T10:52:08+08:00",
        //           name: "松子",
        //           icon: "https://g1.ningmengxinxi.cn/file/df98336e-2e2d-4dbc-bbb4-4e88175059d2.png",
        //           pay_asset: "",
        //           pay_amount: "10",
        //           sequence: 1,
        //           dinosaur_info: {
        //             id: 0,
        //             created_at: "0001-01-01T00:00:00Z",
        //             updated_at: "0001-01-01T00:00:00Z",
        //             name: "",
        //             icon: "",
        //             fear_turret_id: 0,
        //             category: 0,
        //             hit_points: 0,
        //             gif_path: "",
        //           },
        //           gif_path:
        //             "https://g1.ningmengxinxi.cn/file/df98336e-2e2d-4dbc-bbb4-4e88175059d2.png",
        //         },
        //         level: 1,
        //         sequence: 1,
        //       },
        //       {
        //         id: 9082232,
        //         created_at: "2024-07-08T17:15:20+08:00",
        //         updated_at: "2024-07-08T17:15:20+08:00",
        //         paradise_id: 670771,
        //         user_id: 200008,
        //         uid: "",
        //         avatar: "",
        //         turret_id: 3,
        //         turret_info: {
        //           id: 3,
        //           created_at: "2022-05-26T01:59:53+08:00",
        //           updated_at: "2023-03-02T10:52:22+08:00",
        //           name: "肉块",
        //           icon: "https://g1.ningmengxinxi.cn/file/b75b69b8-b8e2-4b29-a31a-c27c996714dc.png",
        //           pay_asset: "",
        //           pay_amount: "10",
        //           sequence: 2,
        //           dinosaur_info: {
        //             id: 0,
        //             created_at: "0001-01-01T00:00:00Z",
        //             updated_at: "0001-01-01T00:00:00Z",
        //             name: "",
        //             icon: "",
        //             fear_turret_id: 0,
        //             category: 0,
        //             hit_points: 0,
        //             gif_path: "",
        //           },
        //           gif_path:
        //             "https://g1.ningmengxinxi.cn/file/b75b69b8-b8e2-4b29-a31a-c27c996714dc.png",
        //         },
        //         level: 1,
        //         sequence: 2,
        //       },
        //     ],
        //   },
        // ]);
      } else {
        message.message(res?.message);
      }
    } catch (error) {
      message.message("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible && userId) loadMoreData();
    else setData([]);
  }, [visible, userId]);

  return (
    <Modal
      title={null}
      visible={visible}
      closable={false}
      footer={null}
      onCancel={close}
      className="ap_modal_consume"
    >
      <div className="ap_modal2">
        <div className="ap_modal_box2">
          <div
            className="ap_modal_header2"
            style={{
              backgroundImage: `url(${require("../../../../assets/bg_popUp_top@2x.png")})`,
            }}
          >
            <span className="title">消费记录</span>
          </div>
          <div className="ap_modal_body2">
            <div className="consume">
              <div className="consume_th">
                <div className="item">花费</div>
                <div className="item">奖励</div>
              </div>
              <ul className="consume_list">
                {data &&
                  data?.length > 0 &&
                  data.map((item) => (
                    <li className="consume_box" key={"consume_" + item?.id}>
                      <div className="consume_item">
                        <div className="top">
                          <div className="value">
                            <img
                              className="icon"
                              src={require("../../../../assets/icon_main_wallet_jifen@3x.png")}
                              alt=""
                            />
                            <span>x{item?.pay_amount}</span>
                          </div>
                          <span
                            className={`status ${
                              item?.status === 1 ? "success" : ""
                            }`}
                          >
                            {item?.status === 1 ? "准备成功" : "准备失败"}
                          </span>
                        </div>
                        <div className="content">
                          <div className="row">
                            <span className="label">出现的小动物</span>
                            <span className="label">准备午餐</span>
                          </div>
                          <div className="row2 margin_top4">
                            <div className="info_img">
                              {/* {item?.dinosaur_info.map((infoImg) => (
                                <img src={infoImg.icon} alt="" />
                              ))} */}
                              <img
                                src={
                                  item?.dinosaur_paradise?.dinosaur1_info.icon
                                }
                                alt=""
                              />
                              <img
                                src={
                                  item?.dinosaur_paradise?.dinosaur_info.icon
                                }
                                alt=""
                              />
                            </div>
                            {/* <img src={item?.dinosaur_info.icon} alt="" /> */}
                            <div className="img_list">
                              {item?.turret_list &&
                                item?.turret_list?.length > 0 &&
                                item?.turret_list.map((img1) => (
                                  <img
                                    key={"img_item_" + img1?.id}
                                    src={img1?.turret_info?.icon}
                                    alt=""
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <span className="label">
                            {moment(item?.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <span className="label">仅展示近7天的数据哦～</span>
      </div>
    </Modal>
  );
});

export default ConsumeModal;
