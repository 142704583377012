import React from "react";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setIsZiDongDuiHuan } from "../../../../store/app";
import axios from "axios";
import { baseUrl1, baseUrl2 } from "../../../../config";
import { message, Switch } from "antd";
import moment from "moment";
import "./index.less";

// new 获取积分弹窗
const GetIntegralModal = React.forwardRef((props, ref) => {
  const { count, shengDou, getUserWallet } = props;
  const dispatch = useDispatch();

  const token = useSelector((state) => state.App.token);
  const isZiDongDuiHuan = useSelector((state) => state.App.isZiDongDuiHuan);

  const [integralIndex, setIntegralIndex] = React.useState(0);
  const [integralItem, setIntegralItem] = React.useState(undefined);
  // const [isZiDongDuiHuan, setIsZiDongDuiHuan] = React.useState(0);

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await axios({
        method: "post", //请求方法
        data: {
          token: token,
        },
        url: `${baseUrl2}/api/v1/product/turntableProduct`,
      });
      if (data?.status === 10000) {
        setData(data?.data);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };
  const buy = async () => {
    if (loading) {
      return;
    }
    if (!integralItem) {
      message.warning("请先选择套餐");
      return;
    }
    setLoading(true);
    try {
      const { data } = await axios({
        method: "post", //请求方法
        data: {
          token: token,
          sku_id: integralItem?.skuList[0]?.id,
          amount: 1,
        },
        url: `${baseUrl2}/api/v1/product/buy`,
      });
      if (data?.status === 10000) {
        message.success("操作成功");
        getUserWallet && getUserWallet();
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  React.useEffect(() => {
    if (data && data?.length > 0 && !integralItem) {
      setIntegralItem(data[integralIndex]);
    }
  }, [data]);
  React.useEffect(() => {
    if (visible) {
      if (isZiDongDuiHuan === undefined) dispatch(setIsZiDongDuiHuan(1));
      getUserWallet();
      getData();
    } else {
      setData([]);
    }
  }, [visible, isZiDongDuiHuan]);

  return (
    <Modal
      title={null}
      visible={visible}
      closable={false}
      footer={null}
      onCancel={close}
      className="ap_modal_food"
    >
      <div className="ap_modal1">
        <div
          className="ap_modal_header1"
          style={{
            backgroundImage: `url(${require("../../../../assets/bg_popUp_top@2x.png")})`,
          }}
        >
          <span className="title">获得积分</span>
          <img
            className="icon_close"
            onClick={close}
            src={require("../../../../assets/sys_btn_delete_list@2x copy.png")}
            alt=""
          />
        </div>
        <div className="ap_modal_body1">
          <div className="integral_list">
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => (
                <div
                  className="integral_box"
                  key={item?.id}
                  onClick={() => {
                    setIntegralIndex(index);
                    setIntegralItem(item);
                  }}
                >
                  <div
                    className={`integral_item ${
                      integralIndex === index ? "active" : ""
                    }`}
                  >
                    <img
                      className="icon"
                      src={item?.goods_info?.icon_path}
                      alt=""
                    />
                    <div className="info">
                      <span className="name">
                        *{item?.skuList[0]?.effective_time}天
                      </span>
                      <span className="content">
                        赠{item?.skuList[0]?.send_amount}积分
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="integral_value">
            {/* <span className="label">我的积分：</span> */}
            <img
              className="icon"
              src={require("../../../../assets/icon_points@2x.png")}
              alt=""
            />
            <span className="value">{count ?? 0}</span>
            <Switch
              className="jifen_switch"
              checkedChildren="开启"
              unCheckedChildren="关闭"
              checked={isZiDongDuiHuan - 0 === 1}
              onChange={(e) => {
                dispatch(setIsZiDongDuiHuan(e ? 1 : 0));
              }}
            />
            <span className="jifen_label">(自动兑换)</span>
          </div>
          <div
            className="shendou"
            onClick={() => {
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    type: "navigation",
                    params: {
                      name: "RechargeSoundBeans",
                    },
                  })
                );
              }
            }}
          >
            <img
              className="icon"
              src={require("../../../../assets/icon_main_wallet_shendou@3x.png")}
              alt=""
            />
            <span className="value">{shengDou ?? 0}</span>
            <img
              className="icon2"
              src={require("../../../../assets/btn_main_chat_add@3x.png")}
              alt=""
            />
          </div>
        </div>
        <div className="ap_modal_footer1">
          <div className="btn_box">
            <div className="btn_payment" onClick={buy}>
              支付 {integralItem?.skuList[0]?.pay_amount ?? 0} 圈豆
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default GetIntegralModal;
