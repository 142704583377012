import "./index.less";

const Progress = (props) => {
  const { bossState } = props;
  const progress = Math.min(
    1,
    Number(bossState?.accumulative ?? 0) / Number(bossState?.consume ?? 1)
  );

  return (
    <div className="boss_house">
      <div className="progress">
        <span className="boss_text">Boss进场中</span>
        <div className="boss_progress">
          <div
            className="progress_value"
            style={{ width: `${Math.floor((progress - 0) * 100)}%` }}
          ></div>
          <span className="progress_text">
            {Math.floor((progress - 0) * 100)}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default Progress;
