import "./App.less";

import GameIndex from "./pages/GameIndex";
import Game from "./pages/Game";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { store } from "./store";
import { Provider } from "react-redux";
import { message } from "antd";

message.config({
  maxCount: 1,
  top: "50%",
  duration: 1,
});

function App() {
  return (
    <div className="App" style={{ backgroundColor: "transparent !important" }}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {/* 启动页 */}
            {/* <Route path="/" element={<GameIndex />} /> */}
            {/* 游戏主界面 */}
            <Route
              path="/"
              element={<Navigate to={`/animal${window.location.search}`} />}
            ></Route>
            <Route path="/animal" element={<Game />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
