import React from "react";
import "./index.css";

// new 游戏结束弹窗
const ShowGameEndModel = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const close = () => {
    setVisible(false);
  };
  const show = (turret) => {
    setVisible(true);
    setTimeout(() => {
      close();
    }, 3000);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
    };
  });

  return (
    <>
      {visible && (
        <div className="show_game_end_model">
          <div
            className="show_game_end_model_box"
            onClick={() => {
              close();
            }}
          >
            <div className="show_game_end_model_content">
              <div>小动物什么也没有吃到~</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default ShowGameEndModel;
